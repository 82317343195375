import { LanguageLocale } from '../constants/locales';
import { PaymentMethodType } from './PaymentMethodType';

export interface ITSAppOrderReferenceResponse {
    appRef: string;
    merchantRef: string;
    merchantClientRef: string;
    callerRef?: string;
    merchantContextRef?: string;
    meta?: { [key: string]: any; };
}
export interface ISimpleTemplateResponse {
    type: 'simple';
    simple: ISimpleOrderTemplateResponse;
}

export interface ILogoTemplateResponse {
    type: 'logo';
    logo: ILogoOrderTemplateResponse;
}

export interface ISimpleOrderTemplateResponse {
    title: string;
    desc: string;
    paymentRef: string;
}

export interface ILogoOrderTemplateResponse {
    title: string;
    desc: string;
    paymentRef: string;
    logoUrl: string;
}

export interface IRecurrentDetails {
    // amount in cents
    amount: string,
    // intdate YYYYMMDD
    startOn: number,
    repeatEvery?: number,
    repeatPeriod?: string,
    // intdate YYYYMMDD
    endOnDate?: number;
    endAfterTimes?: number;
}

export interface SchedulePlan {
    // intdate YYYYMMDD
    chargeDate: number,
    // intdate YYYYMMDD
    originalChargeDate: number,
    // amount in cents
    amount: string,
}

export interface IAcquireConfig {
    payMethod: string[];
}

export interface ISTPAcquire extends IAcquireConfig {
    publishableKey: string;
}

export interface IPPAcquire extends IAcquireConfig {
    client_token: string;
    accountId: string;
}

export interface ICKOAcquire extends IAcquireConfig {
}

export interface ICBIAcquire extends IAcquireConfig {
}

export interface IAcquire {
    stp: ISTPAcquire,
    pp: IPPAcquire,
    cko: ICKOAcquire,
    cbi: ICBIAcquire
}

export interface IOneshotPaymentOrderResponse {
    externalRef?: string;
    metadata?: { [key: string]: any };
    currency?: string;
    langLocale: LanguageLocale;
    callbackUrl?: string,
    cancelUrl?: string,
    maxAmount?: number,
    validityStartDate?: number;
    validityEndDate?: number;
    contextId?: string,
    sourceTypes?: PaymentMethodType[];
    template?: ISimpleTemplateResponse | ILogoTemplateResponse;
    type?: string,
    orderKey?: string,
    confirmationPageNote?: string,
    contactRequest: boolean
    cardBrands: string[],
    cardLink2SaveFunding: string[],
    minChargeAmount?: number,
    maxChargeAmount?: number,
    acquire?: IAcquire,

    // link2save - plan related
    plan?: {
        schedules?: {
            plans: SchedulePlan[]
        },
        recurrent?: IRecurrentDetails,
        onetime?: SchedulePlan,
    }

    // old not used???
    state?: string;
    modifiedBy?: string;
    modifiedOn?: string;
    createdOn?: string;
    tsapp?: ITSAppOrderReferenceResponse;
    amount?: number;
    amounts?: IOrderAmounts
    netAmountFlag?: boolean;
}


// "amounts": {
//     "sepa_debit": {
//       "source": "SEPA_DEBIT",
//       "amount": "2110",
//       "netAmount": "2000",
//       "fee": {
//         "event": "CHARGE",
//         "type": "withhold_fees",
//         "amount": "110",
//         "composition": {
//           "source": "SEPA_DEBIT",
//           "feePercent": 0.004999999888241291,
//           "feeFixed": "100"
//         }
//       }
//     }

export interface IOrderAmounts{
    sepa_debit: {
        source: string,
        amount: string,
        netAmount: string,
        fee: {
            event: string,
            type: string,
            amount: string,
            composition:{
                source: string,
                feePercent: number,
                feeFixed: string
            }
        }
    }
}
export interface IOrder extends IOneshotPaymentOrderResponse {
    publishableKey: string;
    companyName: string;
    customTheme?: CustomTheme;
}


export enum EnumCustomColor{
    BLACK = "BLACK",
    WHITE = "WHITE"
}
export interface CustomTheme {
    headerColor?: string
    backgroundMain?: string
    title?: string;
    textColor?: string
    primaryColor?: string
    primaryButtonG1?: string;
    primaryButtonG2?: string;
    primaryButtonText?: string;
    accordionForeground?: string;
    accordionBackground?: string;
    secondaryButtonBackground?: string;
    foregroundMain?: string;
    paymentRef?: string;
    description?: string;
    amount?: string;
    wallet?: string;
    hintColor?: string; 
    secondaryColor?: string;
    inputBorderColor?: string;
    calendarIcon?: EnumCustomColor;
    calendarIconColor?: string;
}

export interface IPlan {
    recurrent?: IRecurrentDetails;
    schedules?: SchedulePlan[];
    oneTime?: SchedulePlan;
    netAmountFlag?: boolean;
}
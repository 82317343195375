import {IJson} from '../../core';
import { AbstractRestGetService } from './base';

export class PaypalService extends AbstractRestGetService<IJson> {
    rootUrl: string = '/paypal/';

    createOrder(data: any, orderKey: string) {
        return this.post(`/${orderKey}/order`, data);
    }

    getClientToken() {
        return this.get(`token`);
    }
 }

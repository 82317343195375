import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router";
import { catchError, first, tap } from "rxjs/operators";
import { WindowWithCustomAppConfig } from "../../core";
import i18n from "../../i18n";
import { useInjection } from "../../ioc";
import { IOrder } from "../../model/IOrder";
import { LinkName } from "../../model/LinkName";
import { LinkStep } from "../../model/LinkStep";
import { PayMeDaoService } from "../../service/dao";
import { changeOrder, cleanState } from "../../store/reducers/linkDataSlice";
import { changeActiveLinkName, changeActiveStep, changeLanguage } from "../../store/reducers/sagaSlice";
import Translate from "../Common/Translate";
import LinkHost from "./LinkHost";
import { IUrlParams } from "../../model/IUrlParams";

export interface IPayMeProps {
  changeOrder: typeof changeOrder;
  changeActiveStep: typeof changeActiveStep;
  changeActiveLinkName: typeof changeActiveLinkName;
  changeLanguage: typeof changeLanguage;
  cleanState: typeof cleanState;
}

const PayMe: FC<IPayMeProps> = (props: IPayMeProps) => {
  // setup link
  props.changeActiveLinkName(LinkName.PayMe);
  props.changeActiveStep(LinkStep.PaymentDetails);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { orderKey } = useParams<IUrlParams>();
  const payMeDaoService = useInjection(PayMeDaoService);

  useEffect(() => {
    const fetchData = async () => {
      return await payMeDaoService
        .retrieveOrder(orderKey as string)
        .pipe(
          first(),
          tap((order: IOrder) => {
            const orderFound = order;

            // set type of order if not returned
            orderFound.type = orderFound.type || "payme";
            props.changeOrder(orderFound);

            i18n.changeLanguage(orderFound.langLocale);

            setTimeout(() => props.changeLanguage(orderFound.langLocale), 0);
            (window as WindowWithCustomAppConfig).applyTheme?.(orderFound.customTheme);

            setIsLoading(false);
          }),
          catchError((e: any) => {
            setIsLoading(false);
            setIsError(true);
            return e;
          })
        )
        .subscribe();
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Translate text={"loading"} />;
  }

  if (isError) {
    return <Redirect to={"/error"} />;
  }

  return (
    <div>
      <title>TS Pay - POS digitale</title>
      <LinkHost />
    </div>
  );
};

export default connect(
  () => {
    return {};
  },
  {
    changeOrder,
    changeActiveStep,
    changeActiveLinkName,
    changeLanguage,
    cleanState,
  }
)(PayMe);

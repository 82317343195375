import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { changeLanguage } from "../../../store/reducers/sagaSlice";
import { connect } from "react-redux";
import { LanguageLocale } from "../../../constants/locales";
import { languageSelector } from "../../../store/selectors/sagaSelectors";
import {RootState} from '../../../store/reducers';
import { orderSelector } from "../../../store/selectors/linkDataSelectors";
import { IOrder } from "../../../model/IOrder";
import { getLocalStorage, LANGUAGE, setLocalStorage } from "../../../utils/storageUtils";

interface IExternalLanguageSelectorProps extends WithTranslation {}

interface IConnectedLanguageSelectorProps {
  readonly activeLanguage: LanguageLocale;
  readonly changeLanguage: typeof changeLanguage;
}

interface ILanguageSelectorProps
  extends IExternalLanguageSelectorProps,
  IConnectedLanguageSelectorProps {
    order: IOrder | null;
  }

interface ILanguageEditableState {
  dropdownOpen: boolean;
}

const languages = [LanguageLocale.IT, LanguageLocale.EN];

/**
 * language dropdown component 
 */
class Index extends React.Component<ILanguageSelectorProps, ILanguageEditableState> {

  componentDidMount(): void {
    if(getLocalStorage(LANGUAGE)){
      const localSetting = getLocalStorage(LANGUAGE)
      this.props.changeLanguage(localSetting.language);
      console.log('localSetting', localSetting.language)
    }
    if (this.props.i18n.language !== this.props.activeLanguage) {
      this.props.i18n.changeLanguage(this.props.activeLanguage);
      console.log('this.props.i18n.language', this.props.i18n.language)
    }

  }

  componentDidUpdate(prevProps: Readonly<ILanguageSelectorProps>): void {
    if(getLocalStorage(LANGUAGE)){
      const localSetting = getLocalStorage(LANGUAGE)
      this.props.changeLanguage(localSetting.language);
      console.log('localSetting A', localSetting.language)
    } else {
      // set defulat
      this.props.changeLanguage(LanguageLocale.IT);
    }
    if (this.props.i18n.language !== this.props.activeLanguage) {
        this.props.i18n.changeLanguage(this.props.activeLanguage);
        console.log('this.props.i18n.language A', this.props.i18n.language)
    }

  }

  changeLanguage = (language: LanguageLocale) => {
    if (this.props.activeLanguage !== language) {
      this.props.changeLanguage(language);
      this.closeDropdown();
      this.forceUpdate();

      let storeLanguage = {
        language: language
      };
      setLocalStorage(LANGUAGE, storeLanguage);
      console.log('storeLanguage', storeLanguage)
    }
  };

  /**
   * from a full language naming to a language that
   * can be displayed to the end user
   * e.g. input: it-IT will display IT
   */
  prettyLanguageName(fullLang: string) {
    return fullLang?.split('-')?.[0]?.trim()?.toUpperCase();
  }

  constructor(props: ILanguageSelectorProps) {
    super(props);

    this.state = {
      dropdownOpen: false,
    }
  }

  openDropdown() {
    // window.alert('open dropdown!');
    // this.dropdownOpen = true;
    this.setState({ dropdownOpen: true })
  }

  dropdownBox() {
    if (this.state.dropdownOpen === true) {
      return (<div>open here!</div>);
    }
  }

  closeDropdown() {
    this.setState({ dropdownOpen: false });
  }

  toggleDropdown() {
    if (!this.state.dropdownOpen) {
      this.openDropdown();
    } else {
      this.closeDropdown();
    }
  }

  render() {
    // if order is not fully loaded don't show language switcher
    // as if the language is english the user will see the italian
    // language being selected by default for few seconds before the
    // order has loaded
    if (!this.props.order) {
      return null;
    }

    return (
      <div>
          <button
            className={`${styles.btnLang} ${styles.selected}`}
          onClick={() => this.toggleDropdown()}
          >
            {this.prettyLanguageName(this.props.activeLanguage)}
        </button>
        
        <div
          onBlur={() => this.closeDropdown()}
          className={`${styles.dropdownBox} ${
            this.state.dropdownOpen === true ?
            styles.display : styles.hide
            }`
          }
        >
          {
            languages.map((lang: LanguageLocale, i: number) => (
              <div
                key={`language-dropdown-select-${i}`}
                className={`${styles.dropdownElement}`}
                onClick={() => this.changeLanguage(lang)}
              >
                {this.prettyLanguageName(lang)}
              </div>
            ))
          }
          </div>
      </div >
    );
  }
}

export default connect(
  (state: RootState) => ({
    activeLanguage: languageSelector(state),
    order: orderSelector(state),
  }),
  {
    changeLanguage
  }
)(withTranslation()(Index));

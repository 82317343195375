import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router";
import { catchError, first, tap } from "rxjs/operators";
import { WindowWithCustomAppConfig, navigateToAcquirerDedicateWebApp, orderRequiredExtraData } from "../../core";
import i18n from "../../i18n";
import { useInjection } from "../../ioc";
import { IOrder } from "../../model/IOrder";
import { LinkName } from "../../model/LinkName";
import { LinkStep } from "../../model/LinkStep";
import { Link2SaveDaoService } from "../../service/dao";
import { changeOrder, cleanState } from "../../store/reducers/linkDataSlice";
import { changeActiveLinkName, changeActiveStep, changeFlow, changeLanguage, FlowType } from "../../store/reducers/sagaSlice";
import Translate from "../Common/Translate";
import StepManager from "./StepManager";
import { IUrlParams } from "../../model/IUrlParams";

export interface ILink2SaveProps {
  changeOrder: typeof changeOrder;
  changeActiveStep: typeof changeActiveStep;
  changeActiveLinkName: typeof changeActiveLinkName;
  changeLanguage: typeof changeLanguage;
  changeFlow: typeof changeFlow;
  cleanState: typeof cleanState;
}

const Link2Save: FC<ILink2SaveProps> = (props: ILink2SaveProps) => {
  props.changeActiveStep(LinkStep.PaymentDetails);
  props.changeActiveLinkName(LinkName.Link2Save);
  props.changeFlow(FlowType.PM_PROVIDED_BY_USER_AND_NOT_SAVED);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { orderKey } = useParams<IUrlParams>();
  const link2SaveDaoService = useInjection(Link2SaveDaoService);

  useEffect(() => {
    const fetchData = async () => {
      return await link2SaveDaoService
        .retrieveOrder(orderKey as string)
        .pipe(
          first(),
          tap((order: IOrder) => {
            const orderFound = order;

            // set type of order if not returned
            orderFound.type = orderFound.type || "link2save";

            props.changeOrder(orderFound);

            i18n.changeLanguage(orderFound.langLocale);

            setTimeout(() => props.changeLanguage(orderFound.langLocale), 0);
            (window as WindowWithCustomAppConfig).applyTheme?.(orderFound.customTheme);

              //redirect to stp or pp when exist a single paymethod
              redirectToPayMethodWebappIfRequired(order);

              //dealy loading if exist a single paymethod
              hideLoading(order);
          }),
          catchError((e: any) => {
            setIsLoading(false);
            setIsError(true);
            return e;
          })
        )
        .subscribe();
    };

    fetchData();
  }, []);

    const hideLoading = (order: IOrder) => {
        const sourceTypes = order.sourceTypes
        if(sourceTypes?.length == 1) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        } else {
            setIsLoading(false);
        }
    }

    const redirectToPayMethodWebappIfRequired = (order: IOrder) => {
        const sourceTypes = order.sourceTypes
        if(sourceTypes?.length == 1 && !orderRequiredExtraData(order)) {
          const payMethodType = sourceTypes[0];
          navigateToAcquirerDedicateWebApp(order, payMethodType);
        }
    }
  

  if (isLoading) {
    return <Translate text={"loading"} />;
  }

  if (isError) {
    return <Redirect to={"/error"} />;
  }

  return (
    <div>
      <title>TS Pay - Checkout</title>
      <StepManager />
    </div>
  );
};

export default connect(
  () => {
    return {};
  },
  {
    changeOrder,
    changeActiveStep,
    changeActiveLinkName,
    changeLanguage,
    cleanState,
    changeFlow,
  }
)(Link2Save);

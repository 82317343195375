import React from 'react';
import styles from "./styles.module.scss";

export interface ICustomError {
    showError: boolean;
    content: React.ReactFragment;
}

const CustomError = (props: ICustomError) => {
    if (!props.showError) {
        return null;
    }

    return (<div className={styles.errorContainer}>{props.content}</div>);
};

export default CustomError;
import {IJson} from '../../core';
import { AbstractRestGetService } from './base';
import {Observable} from 'rxjs';
import {inject} from 'inversify';
import {ErrorHandlerService, IErrorHandlerService} from '../errorHandlerService';
import {ICustomerCreate} from '../../model/ICustomer';

export interface ICustomerDaoService {
    create(data: ICustomerCreate): Observable<any>;
}

export class CustomerDaoService extends AbstractRestGetService<IJson> implements ICustomerDaoService {
    rootUrl: string = '/customer';

    create(data: ICustomerCreate): Observable<any> {
        return this.post('/', data)
    }
}

export const en = {
    browserNotSupported: "Sorry, your browser is not compatible. Update it or use another one in order to complete the operation.",
    invalidUrl: "Sorry, invalid URL.",
    button: {
        next: "Next",
        back: "Back",
        confirm: "Confirm",
        addCard: "Add new payment method",
        remove: "Remove",
        cancel:  "Cancel",
        refresh: "Refresh",
    },
    plan: {
        frequency: "Frequency",
        planSchedule: "schedules plan",
        planRecurrent: "recurring plan",
        planOneTime: "one time plan",
        every: "Every",
        till: "till",
        for: "for",
        date: "Date",
        startDate: "Start Date",
        period: {
          day: "day",
          day_plurarl: "days",
          month: "month",
          month_plural: "months",
          year: "year",
          year_plural: "years",
        }
    },
    orderDetails: {
        amount: "Amount",
        imageUrl: "Logo:",
        title: "Title:",
        desc: "Description:",
        paymentRef: "Reference number:",
        validityEndDate: "validity end date",
        contextId: "ContextID",
        maxAmount: "Maximum amount",
        minAmount: "Minimum amount",
        companyNamePrefix: "TeamSystem Payments S.r.l. on behalf of ",
        charge: "charge",
        charge_plural: "charges"
    },
    step: {
        paymentDetails: {
            title: "PAY NOW",
            subtitle: "Register a payment source for the Athlon Annual Gym Membership",
            card: {
                number: "Card number:",
                expiration: "Expiration date:",
                cvv: "CVV:"
            },
            selectPaymentMethod: "Do you want to use a payment method previously saved in your wallet?",
            registerCard: "Do you want to use a previously saved payment method?",
            amount: "Amount",
            expiration: "*This link expires on ",
            enterDate: "Please enter a valid date"
        },
        paymentConfirmation: {
            title: "Payment Confirmation",
            card: "Credit / Debit Card",
            sepa_debit: "Bank account"
        },
        customerIdentification: {
            title: "Identification",
            info: "Enter your email or mobile number to receive the verification code",
            enterEmail: "Enter email",
            enterYourEmail: "Enter your email",
            enterEmailOrMobileNumber: "Enter email or mobile number",
            email: "E-mail",
            mobileNumber: "Mobile Number",
            wrongCredentials: "Wrong Credentials",
            privacyAndTosDisclaimer: "I accept the <0>Terms Of Service</0> and I have read and agree to <1>Privacy Policy</1>"
        },
        customerVerification: {
            title: {
                line1: "ENTER THE CODE",
                line2: "YOU RECEIVED",
                line2email: "YOU RECEIVED VIA E-MAIL",
                line2mobile: "YOU RECEIVED VIA MOBILE"
            },
            otpCode: "OTP Code",
            noOtpCodeReceivedInfo: "Didn't you get the code? Request a new one",
            enterOtpCode: "Enter OTP Code",
            otpSent: "OTP Sent",
        },
        paymentMethodSave: {
            title: "Authorise",
        },
        paymentMethodList: {
            title: "Select a payment method",
            card: "Credit/Debit Card",
            account: "Bank account",
            expiry: "Expiry",
            modal: {
                info: "Do you confirm?"
            },
            onItem: 'No payment methods.'
        },
        paymentMethodAdd: {
            title: "ADD CARD",
            subtitle: "ENTER THE CARD DATA. IT WILL BE ASSOCIATED WITH PAYMENTS",
            cardNumber: "Card number:",
            expiration: "Expiration date:",
            cvv: "CVV:"
        },
        thankYou: {
            title: 'THANK YOU!',
            subtitle: 'PAYMENT completed successfully!',
            subtitleLink2save: "Authorization Successfully Concluded!",
            orderDetailsLabel: 'OPERATION SUMMARY',
            paymentMethodDetailsLabel: 'Credit/Debit Card',
            contact: {
                label:'Do you need any help?',
                link: 'Contact us!'
            },
            infoMessage:"The charge will be visible on your bank account balance as «TeamSystem Pay»",
            print:'DOWNLOAD',
            newPayment:'NEW PAYMENT',
            transactionCode:'OPERATION NUMBER: '
        },
        tspayDetail: {
            info: "New to TS Pay?",
            urlInfo: 'Click here for more information'
          },
          netAmountChargePdf: {
            info: '*Including charge fee of',
            url: '(Terms and conditions)'
        }
    },
    paymentMethod: {
        email: "Email",
        name: "Name",
        card: "Card",
        cardDesc: "Debit or credit Card",
        pisDesc: "Instant Bank Transfer",
        sepaDebit: "IBAN",
        sepaDebitTitle: "IBAN",
        sepaDebitDesc: "Direct debit on bank account",
        saveCard: "Save the card for future payments (optional)",
        saveSepaDebit: "Save SDD for future payments (optional)",
        cardNumber: "Card number",
        cardExpiry: "Expiry",
        cardCvc: "CVV",
        selectPayMethod: "Select a payment method",
        Link2Save: {
            declaimer: "By providing your IBAN and confirming this payment, you are authorizing TeamSystem Payments S.r.l., to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.",
            declaimerBottom: 'TeamSystem Pay uses advanced anti-fraud systems to ensure the transaction security . Data must be entered by the account holder or authorized personnel. Any abuse will be prosecuted according to the law.'
        },
        Link2Pay: {
            declaimer: "By providing your IBAN and confirming this payment, you are authorizing TeamSystem Payments S.r.l., to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.",
            declaimerBottom: 'TeamSystem Pay uses advanced anti-fraud systems to ensure the transaction security . Data must be entered by the account holder or authorized personnel. Any abuse will be prosecuted according to the law.'
        }
    },
    chargePaymentRefInput : {
        placeholder: "Enter the payment reason"
    },
    customerNameInput : {
        label: "Full name",
        placeholder: "Full name"
    },
    customerAddressInput : {
        label: "Full address",
        placeholder: "Full address"
    },
    tabs: {
        card: "CREDIT CARD",
        sepa_debit: "IBAN"
    },
    accordion: {
        card: "CREDIT / DEBIT CARD",
        sepa_debit: "IBAN (DIRECT DEBIT)"
    },
    removeModal: {
        header: 'CONFIRM REMOVAL',
        info: "Are you sure you want to delete the payment method from the wallet?"
    },
    thankYou: {
        info: "Thank you"
    },
    loading: "You are about to be redirected. Do not close this page.",
    tooltip: {
        saveCard: {
            header: "How does it work? ",
            content: "By selecting this option you choose to store the indicated payment method, so that you can reuse it quickly and securely in future operations with TeamSystem Pay."
        },
        saveSepaDebit: {
            header: "How does it work?",
            content: "By selecting this option you choose to store the indicated payment method, so that you can reuse it quickly and securely in future operations with TeamSystem Pay."
        },
        customerVerification: {
            header: "OTP",
            content: "OTP (One Time Password) indicates a “disposable” password sent via email or SMS that is generated at the moment and can be used only once. Entering the OTP code is necessary to safely authorize the requested operation."
        },
        dateInput: {
            header: "Authorisation ending date",
            content: "You can choose when the source will still be valid. By default there is no expiry",
        },
        paymentSchedulePlan: {
            header: "Original date:"
        }
    },
    error: {
        unknown: "Something went wrong",
        noOrderKey: "No order key",
        noCallbackUrl: "error occurred. Callback not defined",
        invalidOtpCode: "wrong otp code",
        invalidSepaDebit: "Your IBAN is not supported",
        invalidCard: "Your card is not supported",
        invalidBrand: "This card brand is not supported",
        invalidFundingType: "This type of card is not supported",
        invalidMobileNo: "Mobile number is incorrect",
        orderExpired: "This order expired"
    }
};

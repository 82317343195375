import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router";
import { catchError, first, tap } from "rxjs/operators";
import { WindowWithCustomAppConfig, navigateToAcquirerDedicateWebApp, orderRequiredExtraData } from "../../core";
import i18n from "../../i18n";
import { useInjection } from "../../ioc";
import { IOrder } from "../../model/IOrder";
import { LinkName } from "../../model/LinkName";
import { LinkStep } from "../../model/LinkStep";
import { Link2PayDaoService } from "../../service/dao";
import { changeOrder, cleanState } from "../../store/reducers/linkDataSlice";
import { changeActiveLinkName, changeActiveStep, changeLanguage } from "../../store/reducers/sagaSlice";
import Translate from "../Common/Translate";
import LinkHost from "./LinkHost";
import { IUrlParams } from "../../model/IUrlParams";

export interface ILink2PayProps {
  changeOrder: typeof changeOrder;
  changeActiveStep: typeof changeActiveStep;
  changeActiveLinkName: typeof changeActiveLinkName;
  changeLanguage: typeof changeLanguage;
  cleanState: typeof cleanState;
}

const Link2Pay: FC<ILink2PayProps> = (props: ILink2PayProps) => {
  // setup link
  props.changeActiveLinkName(LinkName.Link2Pay);
  props.changeActiveStep(LinkStep.PaymentDetails);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { orderKey } = useParams<IUrlParams>();
  const link2PayDaoService = useInjection(Link2PayDaoService);

  useEffect(() => {
    const fetchData = async () => {
      return await link2PayDaoService
        .retrieveOrder(orderKey as string)
        .pipe(
          first(),
          tap((order: IOrder) => {
            if(order.orderKey === 'DiPpB_VrCc8yiFEvSavH0ZuM5Us'){
              order.customTheme = {
                "title": "white",
                "textColor": "white",
                "primaryButtonG1": "rgb(243, 130, 55)",
                "primaryButtonG2": "rgb(243, 130, 55)",
                "primaryButtonText": "white",
                "accordionForeground": "white",
                "accordionBackground": "black",
                "secondaryButtonBackground": "black",
                "foregroundMain": "black",
                "paymentRef": "white",
                "description": "white",
                "amount": "white",
                "wallet": "white",
                "hintColor": "#535658",
                "secondaryColor": "white",
                "headerColor": "#152935",
                "primaryColor": "white",
                "inputBorderColor": "#535658"
              };
            }
            const orderFound = order;

            // set type of order if not returned
            orderFound.type = orderFound.type || "link2pay";
            props.changeOrder(orderFound);

            i18n.changeLanguage(orderFound.langLocale);

            setTimeout(() => props.changeLanguage(orderFound.langLocale), 0);
            (window as WindowWithCustomAppConfig).applyTheme?.(orderFound.customTheme);

            //redirect to stp or pp when exist a single paymethod
            redirectToPayMethodWebappIfRequired(order);

            //dealy loading if exist a single paymethod
            hideLoading(order);

          }),
          catchError((e: any) => {
            setIsLoading(false);
            setIsError(true);
            return e;
          })
        )
        .subscribe();
    };

    // Call GainSight
    const aptrinsic: (id: string, payload: Object) => void = (window as any).aptrinsic;
    if (typeof aptrinsic !== 'undefined') {
      console.log('### Calling gainsight with id: ', orderKey);
      aptrinsic('identify', {
        id: orderKey,
        type: 'link2Pay'
      });
    }

    const redirectToPayMethodWebappIfRequired = (order: IOrder) => {
      const sourceTypes = order.sourceTypes
      if(sourceTypes?.length == 1 && !orderRequiredExtraData(order)) {
        const payMethodType = sourceTypes[0];
        navigateToAcquirerDedicateWebApp(order, payMethodType);
      }
    }

    fetchData();
  }, []);

  const hideLoading = (order: IOrder) => {
    const sourceTypes = order.sourceTypes
    if(sourceTypes?.length == 1) {
      setTimeout(() => {
              setIsLoading(false);
            }, 1000);
    } else {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Translate text={"loading"} />;
  }

  if (isError) {
    return <Redirect to={"/error"} />;
  }

  return (
    <div>
      <title>TS Pay - Checkout</title>
      <LinkHost />
    </div>
  );
};

export default connect(
  () => {
    return {};
  },
  {
    changeOrder,
    changeActiveStep,
    changeActiveLinkName,
    changeLanguage,
    cleanState,
  }
)(Link2Pay);

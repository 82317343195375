import React from 'react';
import Translate from '../Translate/index';
import styles from './styles.module.scss';


interface IPaymentMethodOptionProps {
    style?: any;
    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    title?: string;
    image?: string;
    action: () => void;
}

class PaymentMethodOption extends React.Component<IPaymentMethodOptionProps> {

    renderTitle() {
        if(this.props.title) {
            return (
                <span className={styles.title}>
                    <Translate text={this.props.title}/>
                </span>
            );
        }
    }

    renderIconButtonContent() {
        return (
            <>
                {this.renderIcon()}
                {this.renderTitle()}
            </>
        )
    }

    renderIcon() {
        if(this.props.icon) {
            const IconComponent = this.props.icon;
            return (
                <div className={styles.iconContainer}>
                    <IconComponent className={styles.icon} />
                </div>
            );
        }
    }

    renderImageButtonContent() {
        return <div className={this.props.image} />;
    }

    renderButtonContent() {
        if(this.props.icon) {
            return this.renderIconButtonContent();
        } else {
            return this.renderImageButtonContent();
        }
    }

    render() {
        return (
            //pmoContainer di default e per decidere se il button è abilitato 
            <div className={`${styles.pmoContainer} ${this.props.style}`} onClick={this.props.action}>
                {this.renderButtonContent()}
            </div>
        );
    }
}

export default (PaymentMethodOption);
import {IJson} from '../../core';
import { AbstractRestGetService } from './base';
import {ITargetRequest, IValidationRequest} from '../../model/IVerification';
import {Observable} from 'rxjs';

export interface IAuthenticationDaoService {
    // VerificationRequest(data: ITargetRequest): Observable<any>;
    // VerificationValidation(data: any): Observable<any>;
    TOTPRequest(data: ITargetRequest): Observable<any>;
    TOTPValidation(data: IValidationRequest): Observable<any>;
}

export class AuthenticationDaoService extends AbstractRestGetService<IJson> implements IAuthenticationDaoService {
    rootUrl: string = '/authentication';

    TOTPRequest(data: ITargetRequest): Observable<any> {
        return this.put('/', {
            type: 'TOTPRequest',
            data: data
        })
    }

    TOTPValidation(data: IValidationRequest): Observable<any>  {
        return this.put('/', {
            type: 'TOTPValidation',
            data: data
        })
    }
}

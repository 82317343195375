import {IJson} from '../../core';
import { AbstractRestGetService } from './base';
import {Observable} from 'rxjs';

export interface IHashDaoService {
    getHash(data: any): Observable<any>;
}

export class HashDaoService extends AbstractRestGetService<IJson> implements IHashDaoService {
    rootUrl: string = '/hash';

    getHash(data: any): Observable<any> {
        return this.put('/', data);
    }
}

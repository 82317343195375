import React from 'react';
import {inject} from 'inversify';
import {ErrorHandlerService} from '../../../service';
import type {IErrorHandlerService} from '../../../service/errorHandlerService';
import { DEFAULT_LANG_LOCALE, LanguageLocale } from '../../../constants/locales';
import i18n from '../../../i18n';
import CurrencySymbol from '../CurrencySymbol';
import {getPriceConverterConfigFromLanguage, IPriceConverterConfig} from '../../../core/index';


interface IPriceConverterProps {
    amount?: any;
    currency?: string;
    config?: IPriceConverterConfig;
    language?: LanguageLocale;
}

class CurrencyConverter extends React.Component<IPriceConverterProps> {

    @inject(ErrorHandlerService) private errorHandlerService: IErrorHandlerService;

    render() {
        if (!this.props.amount) {
            this.errorHandlerService.handleInternalError('[CurrencyConverter]: Amount or Currency not specified.');
            return null;
        }

        const language: LanguageLocale = this.props.language || i18n.language as LanguageLocale || DEFAULT_LANG_LOCALE;

        let config: IPriceConverterConfig = getPriceConverterConfigFromLanguage(language, this.props.config);

        let amount: any = null;
        try {
            amount = parseInt(this.props.amount) / 100;
        } catch {
            this.errorHandlerService.handleInternalError('[CurrencyConverter]: Wrong amount type.');
            return null;
        }

        amount = amount.toFixed(config.decimal);
        amount = amount.replace('.', config.decimalSign);

        return <span>
            {amount} <CurrencySymbol currency={this.props.currency} />
        </span>;
    }
}

export default CurrencyConverter;
import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {orderSelector, savePaymentMethodSelector} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import Translate from '../../Common/Translate';
import OrderDetails from '../../Common/OrderDetails';
import 'moment/locale/it';
import {LinkStep} from '../../../model/LinkStep';
import {changeFlow, changeActiveStep, FlowType} from '../../../store/reducers/sagaSlice';
import {changeSavePaymentMethod} from '../../../store/reducers/linkDataSlice';
import ExpirationInfo from '../../Common/ExpirationInfo';
import TspayInformationalPdf from '../../Common/TspayInformationalPdf';
import CancelButton from '../../Common/CancelButton';
import PaymentMethodOptionList from '../../Common/PaymentMethodOptionList';
import OrderEmailNoteBox from '../../Common/OrderEmailNoteBox';


interface IPaymentDetailsProps {
    order: IOrder | null;
    savePaymentMethod: boolean;
    changeFlow: typeof changeFlow;
    changeSavePaymentMethod: typeof changeSavePaymentMethod;
    changeActiveStep: typeof changeActiveStep;
}

class PaymentDetails extends React.Component<IPaymentDetailsProps> {

    componentDidMount() {
        const flow = this.props.savePaymentMethod ?
            FlowType.PM_PROVIDED_BY_USER_AND_SAVED : FlowType.PM_PROVIDED_BY_USER_AND_NOT_SAVED;
        this.props.changeFlow(flow);
    }

    renderNetAmountChargePdf = () => {
        if(this.props.order?.netAmountFlag){
            let orderFee = 0;
            if(this.props.order.amounts?.sepa_debit.fee.amount) {
                orderFee = +(this.props.order.amounts.sepa_debit.fee.amount) / 100;
            }
            return <TspayInformationalPdf orderFee={orderFee}/>
        }
    }

    renderCancelButton = () => {
        if(this.props.order?.cancelUrl){
            return <CancelButton />;
        }
    }

    render() {
        if (!this.props.order || !this.props.order.sourceTypes || !Array.isArray(this.props.order.sourceTypes)) {
            return null;
        }

        return (
            <React.Fragment>
                <h1 className="header title"><Translate text={`step.paymentDetails.title`}/></h1>                
                <OrderDetails config={{amount: {class: 'center'}}}/>
                {this.renderNetAmountChargePdf()}

                <OrderEmailNoteBox />

                <div className="accordionContainer" id="pmAccordion">
                    <PaymentMethodOptionList />
                    
                    <div>
                        <div style={{marginTop: "2rem"}}></div>
                        {this.renderCancelButton()}
                    </div>
                    <ExpirationInfo />

                </div>
            </React.Fragment>
        );
    }

    private goToPaymentMethodList = () => {
        this.props.changeFlow(FlowType.PM_TAKEN_FROM_LIST);
    };
}

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            savePaymentMethod: savePaymentMethodSelector(state),
        };
    },
    {
        changeFlow,
        changeActiveStep,
        changeSavePaymentMethod
    }
)(PaymentDetails);
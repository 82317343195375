import {IJson} from '../../core';
import {AbstractRestGetService} from './base';
import {Observable} from 'rxjs';
import {IConfirmIntent, ICreateExpect} from '../../model/IExpect';

export interface ILink2PayDaoService {
    retrieveOrder(orderKey: string): Observable<any>;
    createPaymentIntent(data: ICreateExpect): Observable<any>;
    confirmPaymentIntent(data: IConfirmIntent, chargeKey: string): Observable<any>;
}

export class Link2PayDaoService extends AbstractRestGetService<IJson> implements ILink2PayDaoService {
    rootUrl: string = '/link2Pay/';

    retrieveOrder(orderKey: string): Observable<any> {
        return this.get(orderKey);
    }

    createPaymentIntent(data: ICreateExpect): Observable<any> {
        return this.post(`${data.orderKey}/expect`, data);
    }

    confirmPaymentIntent(data: IConfirmIntent, chargeKey: string): Observable<any> {
        return this.put(`${data.orderKey}/expect/${chargeKey}`, data);
    }
}
